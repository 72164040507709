<template>
<!-- 算法刷题面试指导 -->
  <div class="mockInterview">
    <div class="pagebanner" :style="{ backgroundImage: 'url(' + img + ')' }">
      <div class="pageTitle">
        1V1北美CS留学求职咨询
        <div class="subtitle">
          1v1对话创始人，针对性答疑，梳理留学找工问题 
          <br />
          定制专属你的留学求职方案，帮你解决留学找工困境
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="g-section">
        <div class="g-header">
          <h3>1v1深度咨询，帮你突破北美CS留学求职困境</h3>
          <p>梳理北美CS留学求职问题，给出最高效的解决方案</p>
        </div>
		<div class="container">
			<ul class="zx-cells">
				<li>
					<div class="zx-card hoverUp">
						<img src="../../assets/images/onetone/2.png" alt="" />
						<div class="flex-col">
							<div class="card-tt">梳理问题</div>
							<div class="card-desc">360度了解你遇到的问题以及形成背景</div>
						</div>
					</div>
				</li>
				<li>
					<div class="zx-card hoverUp">
						<img src="../../assets/images/onetone/3.png" alt="" />
						<div class="flex-col">
							<div class="card-tt">评估个人背景</div>
							<div class="card-desc">全面评估你的个人背景，从学业、工作经历、项目经历等等</div>
						</div>
					</div>
				</li>
				<li>
					<div class="zx-card hoverUp">
						<img src="../../assets/images/onetone/4.png" alt="" />
						<div class="flex-col">
							<div class="card-tt">答疑解惑</div>
							<div class="card-desc">综合问题以及个人背景，分析原因，给出针对性建议</div>
						</div>
					</div>
				</li>
				<li>
					<div class="zx-card hoverUp">
						<img src="../../assets/images/onetone/5.png" alt="" />
						<div class="flex-col">
							<div class="card-tt">制定方案</div>
							<div class="card-desc">全方位综合考量之后，制定适合你的可执行方案</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
      </div>
	  <div class="serve-banner">
	  	<div class="serve-title">服务形式</div>
	  	<div class="serve-tag">
	  		<div class="tag">1v1深度咨询（45-60分钟）</div>
	  		<div class="tag">Online模式：无地区限制，使用远程音频</div>
	  	</div>
	  </div>
	  <div class="g-section">
	    <div class="g-header">
	      <h3>快速报名，获取你的专属定制咨询</h3>
	    </div>
		<div class="container">
			<div class="quick-row">
				<div class="col">
					<div class="quick-card hoverUp">
						<div class="q-icon"><img src="../../assets/images/onetone/weixin.png" alt="" /></div>
						<div class="q-grid">
							免费咨询<br>初谈确定咨询问题
						</div>
					</div>
				</div>
				<div class="arrow"><img src="../../assets/images/onetone/arrow.png" alt="" /></div>
				<div class="col">
					<div class="quick-card hoverUp">
						<div class="q-icon"><img src="../../assets/images/onetone/bank.png" alt="" /></div>
						<div class="q-grid">
							在线支付
						</div>
					</div>
				</div>
				<div class="arrow"><img src="../../assets/images/onetone/arrow.png" alt="" /></div>
				<div class="col">
					<div class="quick-card hoverUp">
						<div class="q-icon"><img src="../../assets/images/onetone/1v1.png" alt="" /></div>
						<div class="q-grid">
							1v1咨询<br>留学/求职/跳槽/翻墙等问题
						</div>
					</div>
				</div>
				<div class="arrow"><img src="../../assets/images/onetone/arrow.png" alt="" /></div>
				<div class="col">
					<div class="quick-card hoverUp">
						<div class="q-icon"><img src="../../assets/images/onetone/deng.png" alt="" /></div>
						<div class="q-grid">
							给出专业建议<br>定制专属方案
						</div>
					</div>
				</div>
			</div>
		</div>
	  </div>

      <div class="priceBanner">
        <div class="priceBannerArea">
          <div class="priceBannerContent">
            <img src="@/assets/imgs/priceBanner.png" alt="" />
            <div class="priceBannerInner">
              <div class="priceLeft">
                <div class="prices">
                  <span class="mainTitle">{{ classInfo.courseName }}</span>
				  <span class="subTitle">服务价格：</span>
				  <span
				    style="
				      font-size: 28px;
				      color: #de1306;
				      line-height: 42px;
				      vertical-align: top;
				    "
				  >
				    ${{ classInfo.usdAmount }}</span
				  >
				  <span
				    style="
				      font-size: 16px;
				      color: #e02020;
				      line-height: 42px;
				      vertical-align: top;
				    "
				    >&nbsp;/&nbsp;&yen;{{ classInfo.amount }}</span
				  >
                </div>
              </div>
              <div class="priceRight">
                <div class="pricesTips">
                  <div class="dollar">支持美元分期付款</div>
                  <div class="huabei">支持花呗分期</div>
                </div>
                <el-button class="buyNow" @click="buyCombo"
                  >立即购买</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<leetDialog></leetDialog>
  </div>
</template>
<script>
import mockMix from "@/utils/mockMix";
import leetDialog from "@/components/leetDialog.vue";
export default {
  
  components: {
  	leetDialog
  },
  data() {
    return {
		img: require("../../assets/images/onetone/banner.jpg"),
	};
  },
  mixins: [mockMix],
};
</script>
<style scoped lang="scss">
.card-tt{
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 10px;
}
.card-desc{
	font-size: 14px;
}
.zx-cells{
	display: flex;
	flex-wrap: wrap;
	padding: 0 20px;
	li{
		width: 50%;
		padding:15px 20px;
	}
	.zx-card{
		padding: 16px;
		display: flex;
		align-items: center;
		box-shadow: 0px 0px 10px 0px rgba(55,55,55,0.1);
		border-radius: 10px;
		img{
			width: 120px;
			height: 120px;
			margin-right: 15px;
		}
	}
}

.quick-row{
	display: flex;
	align-items: center;
	padding: 0 20px;
	.q-grid{
		line-height: 1.8;
	}
	.arrow{
		padding: 30px;
	}
	.q-icon{
		margin-bottom: 20px;
	}
	.col{
		flex: 1;
		min-width: 0;
	}
	.quick-card{
		text-align: center;
		padding-top: 70px;
		height: 270px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(55,55,55,0.1);
		border-radius: 10px;
	}
}
.serve-tag{
	display: flex;
	justify-content: center;
	.tag{
		display: inline-block;
		margin: 0 10px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		color: #fff;
		padding: 10px 20px;
		text-align: center;
		width: 300px;
		border-radius: 5px;
	}
}
.serve-banner{
	background: url(../../assets/images/onetone/serve.jpg) no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 200px;
	color: #ffffff;
	.serve-title{
		font-size: 32px;
		margin-bottom: 20px;
	}
}
	.container{
		max-width: 1200px;
		margin: 0 auto;
	}
.pagebanner {
  width: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  height: 460px;
  .pageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    .subtitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.g-section {
  width: 100%;
  margin: 0 auto;
  padding: 46px 0;
  background: #f8f8f8;
  &.gray {
    background: rgba(240, 241, 242, 0.8);
  }
  .g-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .g-header h3 {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .g-header p {
    margin-top: 8px;
    color: #676767;
    font-size: 16px;
    margin-bottom: 48px;
  }
}

.plans {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .plan {
    width: 520px;
    padding: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 30px;
    img {
      vertical-align: top;
      width: 146px;
    }
    .plan-rightText {
      display: inline-block;
      width: calc(100% - 146px);
      .plan-title {
        padding: 16px 0;
        padding-top: 32px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e59f3c;
        line-height: 24px;
      }
      .plan-description {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }
    }
  }
}
.threeInterviews {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .interviews {
    text-align: center;
  }
  .interviewIcon {
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    padding: 30px;
    margin: 0 60px;
    img {
      width: 42px;
      height: 42px;
      vertical-align: top;
    }
  }
  .interviewType {
    margin-top: 20px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e59f3c;
    line-height: 27px;
  }
}
.fourSteps {
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .arrow,
  .step {
    margin: 0 24px;
  }
  .step {
    text-align: center;
    padding: 62px 54px;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 28px;
    .des {
      margin-top: 8px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #757575;
      line-height: 20px;
    }
  }
  .arrow {
    line-height: 166px;
    img {
      width: 24px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.title-description {
  margin-top: 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 24px;
}
.getInIcon {
  width: 36px;
  margin-bottom: 24px;
}

.priceBanner {
  width: 100%;
  min-height: 60px;
  position: fixed;
  bottom: 50px;
  z-index: 99;
}
.priceBannerArea {
  width: 1200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.priceBannerContent {
  position: relative;
}
.priceBannerInner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}
.pricesTips .dollar {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
}
.pricesTips .huabei {
  background: rgba(10, 122, 255, 0.1);
  color: #0a7aff;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
}
.priceBannerInner .prices {
  color: #ff3b30;
  font-size: 24px;
  min-height: 30px;
  .mainTitle {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 42px;
    margin-right: 30px;
  }
  .subTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 42px;
    vertical-align: top;
    margin-right: 16px;
  }
}
.priceLeft,
.priceRight {
  display: inline-block;
  line-height: 1.6;
}
.priceLeft {
  padding-left: 60px;
}
.priceRight {
  float: right;
  padding-right: 82px;
}
.priceRight .classHelp {
  line-height: 35px;
  background: #ff9f0a;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceRight .buyNow {
  line-height: 35px;
  background: #ff3b30;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceBannerContent img {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
.pricesTips {
  display: inline-block;
  margin-right: 12px;
}
.hoverUp {
    transition: .4s all;
}

.hoverUp:hover {
    transform: translateY(-10px);
}

</style>